<template>
  <b-row>
    <b-col xl="6" lg="6" md="6" sm="12">
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12">
          <b-card class="card-transaction" no-body>
            <b-card-header>
              <b-card-title>Kas CEO</b-card-title>
              <b-link v-b-tooltip.hover.top="'Klik disini untuk ke menu Kas'" @click.prevent="$router.push(`/kas`)">
                <feather-icon icon="ExternalLinkIcon" size="18" class="cursor-pointer" />
              </b-link>
            </b-card-header>

            <b-card-body>
              <div v-for="transaction in id_kas" :key="transaction.id" class="transaction-item">
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar rounded size="42" :variant="transaction.saldo <= 0 ? 'light-danger' : 'light-success'">
                      <feather-icon size="18" :icon="transaction.saldo <= 0 ? 'PocketIcon' : 'DollarSignIcon'" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ transaction.nama }}
                    </h6>
                    <small>{{ transaction.keterangan }}</small>
                  </b-media-body>
                </b-media>
                <div class="font-weight-bolder" :class="transaction.saldo <= 0 ? 'text-danger' : 'text-success'">
                  Rp. {{ formatRupiah(transaction.saldo) }}
                </div>
              </div>
              <hr />

              <div class="transaction-item">
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar rounded size="42" :variant="getNominal(id_kas) <= 0 ? 'light-danger' : 'light-success'">
                      <feather-icon size="18" :icon="getNominal(id_kas) <= 0 ? 'PocketIcon' : 'DollarSignIcon'" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      Saldo
                    </h6>
                    <small>Terkini</small>
                  </b-media-body>
                </b-media>
                <div class="font-weight-bolder" :class="getNominal(id_kas) <= 0 ? 'text-danger' : 'text-success'">
                  Rp. {{ formatRupiah(getNominal(id_kas)) }}
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12">
          <b-overlay :show="loading">
            <b-card no-body class="card-statistics">
              <b-card-header>
                <b-card-title>Rekap Penjualan</b-card-title>
                <b-card-text class="font-small-2 mr-25 mb-0">
                  <!-- <b-button v-b-tooltip.hover.top="'Klik disini untuk filter tanggal dan tipe komisi'" variant="link"
                  id="popover-reactive-1" ref="button">
                  Filter 
                </b-button>-->
                  <b-link v-b-tooltip.hover.top="'Klik disini untuk ke menu Transaksi'"
                    @click.prevent="$router.push(`/transaksi-penjualan`)">
                    <feather-icon icon="ExternalLinkIcon" size="18" class="cursor-pointer" />
                  </b-link>
                  <!-- <b-popover target="popover-reactive-1" triggers="click" :show.sync="popoverShow" placement="bottomleft"
              ref="popover" @show="onShow" @shown="onShown" @hidden="onHidden">
              <template #title>
                Filter
              </template>
              <div>
                <b-form-group label="Tanggal Mulai" label-size="sm" label-cols="3" class="mb-1">
                  <b-form-datepicker
                    :date-format-options="{ year: '2-digit', month: 'short', day: '2-digit', weekday: 'short' }" size="sm"
                    v-model="tanggal_mulai"></b-form-datepicker>
                </b-form-group>

                <b-form-group label="Tanggal Akhir" label-size="sm" label-cols="3" class="mb-1">
                  <b-form-datepicker
                    :date-format-options="{ year: '2-digit', month: 'short', day: '2-digit', weekday: 'short' }" size="sm"
                    v-model="tanggal_selesai"></b-form-datepicker>
                </b-form-group>

                <b-form-group label="Tipe" label-size="sm" label-cols="3" class="mb-1">
                  <b-form-select v-model="tipekomisi" :options="options" @input="getRekapKomisi()"
                    size="sm"></b-form-select>
                </b-form-group>
                <b-button @click="onClose" size="sm" variant="danger">Cancel</b-button>
                <b-button @click="onOk" size="sm" variant="primary">Ok</b-button>
              </div>
            </b-popover> -->
                </b-card-text>
              </b-card-header>
              <b-card-body class="statistics-body">
                <b-row>
                  <b-col xl="6" lg="6" md="12" sm="12" class="mb-md-0">
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" variant="success">
                          <feather-icon size="24" icon="CheckCircleIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ totaltransaksimasuklunas ? formatRupiah(totaltransaksimasuklunas) : 0 }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          Lunas
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col xl="6" lg="6" md="12" sm="12" class="mb-md-0">
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" variant="danger">
                          <feather-icon size="24" icon="XCircleIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                          {{ totaltransaksimasukbelum ? formatRupiah(totaltransaksimasukbelum) : 0 }}
                        </h4>
                        <b-card-text class="font-small-3 mb-0">
                          Belum Lunas
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row>
                <b-row>

                  <b-col xl="12" lg="12" md="12" sm="12">
                    <chartjs-component-doughnut-chart v-if="loaded" :height="275"
                      :data="doughnutChart.data ? doughnutChart.data : null"
                      :options="doughnutChart ? doughnutChart.options : null" class="mt-2 mb-1" />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-col>
    <b-col xl="6" lg="6" md="6" sm="12">
      <b-row>
        <b-col xl="6" lg="6" md="6" sm="12">
          <!-- <b-link v-b-tooltip.hover.top="'Klik disini untuk ke menu Member'" @click.prevent="$router.push(`/member`)"> -->
            <statistic-card-vertical icon="UsersIcon" :statistic="membertotal ? formatRupiah(membertotal) : 0"
              statistic-title="Member" color="info" />
          <!-- </b-link> -->
        </b-col>
        <b-col xl="6" lg="6" md="6" sm="12">
          <!-- <b-link v-b-tooltip.hover.top="'Klik disini untuk ke menu Barang'" @click.prevent="$router.push(`/barang`)"> -->
            <statistic-card-vertical icon="PackageIcon" :statistic="barangtotal ? formatRupiah(barangtotal) : 0"
              statistic-title="Barang" color="warning" />
          <!-- </b-link> -->
        </b-col>
        <b-col xl="6" lg="6" md="6" sm="12">
          <!-- <b-link v-b-tooltip.hover.top="'Klik disini untuk ke menu Transaksi Penjualan'"
            @click.prevent="$router.push(`/transaksi-penjualan`)"> -->
            <statistic-card-vertical icon="FileIcon" :statistic="totaltransaksi ? formatRupiah(totaltransaksi) : 0"
              statistic-title="Transaksi Penjualan" color="primary" />
          <!-- </b-link> -->
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { $themeColors } from '@themeConfig'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BLink,
  BPopover,
  BAlert,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardGroup,
  BForm,
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BOverlay,
  BCardText,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import ChartjsComponentDoughnutChart from '../../charts-components/ChartjsComponentDoughnutChart.vue'
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28c76f',
  warningColorShade: '#ea5455',
  warningLightColor: '#ea5455',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}
export default {
  components: {
    StatisticCardVertical,
    StatisticCardHorizontal,
    ChartjsComponentDoughnutChart,

    BLink,
    BPopover,
    BAlert,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardGroup,
    BForm,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BModal,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    transactionType(val) {
      if (val) {
        if (val == 1) {
          this.id_akun = this.debitAkun;
        } else {
          this.id_akun = this.kreditAkun;
        }
      }
    },
  },
  data() {
    return {
      loaded: false,
      datachart: null,
      loading: false,
      statusOptions: [
        {
          value: null,
          text: "Semua",
          warna: "primary",
        },
        {
          value: 'pending',
          text: "Pending",
          warna: "info",
        },
        {
          value: 'proses',
          text: "Proses",
          warna: "warning",
        },
        {
          value: 'selesai',
          text: "Selesai",
          warna: "success",
        },
        {
          value: 'dibatalkan',
          text: "Batal",
          warna: "danger",
        },
      ],
      selectedStatus: 'pending',
      jenisOptions: [
        {
          value: null,
          text: "Semua",
        },
        {
          value: "affiliate",
          text: "Komisi Affiliate",
        },
        {
          value: "cabang",
          text: "Komisi Cabang",
        },
        {
          value: "bulan",
          text: "Komisi Bulanan",
        },
      ],
      is_bundled: null,
      BundleOptions: [
        {
          value: 1,
          text: "Ya",
        },
        {
          value: null,
          text: "Tidak",
        },
      ],
      selectedjenis: null,
      jenisOptions2: [
        // {
        //   value: null,
        //   text: "Semua",
        // },
        {
          value: "affiliate",
          text: "Komisi Affiliate",
        },
        {
          value: "cabang",
          text: "Komisi Cabang",
        },
        // {
        //   value: "bulan",
        //   text: "Komisi Bulanan",
        // },
      ],
      selectedjenis2: 'affiliate',
      showModal: false,
      showModalApprove: false,
      required,
      password,
      email,
      confirmed,
      form: {
        id_gudang: "",
        tanggal: new Date(),
        keterangan: "",
      },
      id_gudang: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 20,
      pageOptions: [20, 50, 60],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: true,
      sortDirection: "desc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      iberi: [
        {
          1: "Setuju",
          0: "Belum",
          null: "belum",
        },
        {
          1: "light-success",
          0: "light-danger",
          null: "light-danger",
        },
      ],
      statuskomisi: [
        // {
        //   value: "pending",
        //   text: "Pending",
        // },
        {
          value: "proses",
          text: "Proses",
        },
        {
          value: "selesai",
          text: "Selesai",
        },
        {
          value: "dibatalkan",
          text: "Batal",
        },
      ],
      statuswarna: [
        {
          "pending": "Pending",
          "proses": "Proses",
          "selesai": "Selesai",
          "dibatalkan": "Batal",
          null: "Belum",
        },
        {
          "pending": "light-secondary",
          "proses": "warning",
          "selesai": "light-success",
          "dibatalkan": "light-danger",
          null: "light-info",
        },
      ],
      typewarna: [
        {
          "affiliate": "Affiliate",
          "cabang": "Cabang",
          null: "Belum",
        },
        {
          "affiliate": "light-info",
          "cabang": "light-success",
          null: "light-secondary",
        },
      ],
      iterima: [
        {
          1: "Terima",
          0: "Belum",
          null: "belum",
        },
        {
          1: "light-success",
          0: "light-danger",
          null: "light-danger",
        },
      ],
      fields: [
        {
          key: "urutan",
          label: "#",
        },
        {
          key: "no",
          label: "No",
        },
        { key: "tanggal", label: "Tgl Ajuan", sortable: true },
        { key: "gudang.nama_gudang", label: "Gudang" },
        { key: "keterangan", label: "Keterangan" },
        { key: "total", label: "total" },
        // { key: "diberikan", label: "Finance" },
        // { key: "diterima", label: "Status" },
        { key: "status2", label: "Status", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      fieldsFinance: [
        {
          key: "cek",
          label: "#",
        },
        {
          key: "urutan",
          label: "#",
        },
        // {
        //   key: "no",
        //   label: "No",
        // },
        { key: "created_at", label: "Tgl Ajuan", sortable: true },
        // { key: "gudang.nama_gudang", label: "Gudang" },
        { key: "member.nama_lengkap", label: "Nama" },
        { key: "type", label: "type" },
        { key: "nominal", label: "Total (Rp)" },
        { key: "status", label: "Status" },
        { key: "rekening.nama", label: "rekening" },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
      cek: [],
      showKonfirmasi: false,
      formkonfirmasi: {
        id: null,
        bukti_tf: {
          file: null,
        },
      },
      id_akun: [],
      id_kas: [],
      transactionType: null,
      debitAkun: [],
      kreditAkun: [],
      itemsrekap: null,
      input1: '',
      input1state: null,
      input2: '',
      input2state: null,
      options: [{ text: '- Pilih -', value: '' }, { text: 'Affiliate', value: 'affiliate' }, { text: 'Cabang', value: 'cabang' }],
      input1Return: '',
      input2Return: '',
      popoverShow: false,
      tipekomisi: 'affiliate',
      tanggal_mulai: null,
      tanggal_selesai: null,
      membertotal: null,
      barangtotal: null,
      totaltransaksimasuk: null,
      totaltransaksi: null,
      totaltransaksimasuklunas: null,
      totaltransaksimasukbelum: null,
      //Chart Lunas Belum Lunas

      doughnutChart: null,
      stockData: [
        {
          device: 'Lunas', symbol: 'CheckSquareIcon', color: 'text-success', percentage: 80, upDown: 2,
        },
        {
          device: 'Belum Lunas', symbol: 'XSquareIcon', color: 'text-danger', percentage: 10, upDown: 8,
        },
      ],

    };
  },
  computed: {
    isKreditAkun() {
      return this.transactionType == 2;
    },
    isDebitAkun() {
      return this.transactionType == 1;
    },
    totalKomisi() {
      if (!this.cek || this.cek.length < 1) {
        return 0;
      }

      return this.cek.reduce((nominal, item) => (nominal += parseInt(item.nominal)), 0);
    },
  },
  async mounted() {
    // this.setDataSort()
    // await this.getDataKomisi();
    this.getMember()
    this.getBarang()
    this.getKas();
    this.getTransaksiLunas()
    // this.getTransaksiBelumLunas()
    // this.getAkun();
  },
  methods: {
    getNominal(id_kas) {
      return id_kas.reduce((total, id_kas) => total += id_kas.saldo, 0)
    },
    submitkonfirmasi() {
      this.$refs.vkonfirmasi.validate().then((success) => {
        if (success) {
          const payloadkonfirmasi = [];
          this.cek.forEach((konfir) => {
            let konfirmasi = {
              id: konfir.id,
              // status: this.formkonfirmasi.statuskomisi,
              // akun_id: this.formkonfirmasi.id_akun,
              // kas_id: this.formkonfirmasi.id_kas,
            };
            payloadkonfirmasi.push(konfirmasi);
          });
          this.$store
            .dispatch("ajuan/bundleproses", payloadkonfirmasi)
            .then(() => {
              this.showKonfirmasi = false;
              delete this.cek;
              this.displaySuccess({
                text: "Berhasil diproses",
              });
              this.getDataKomisi();
              // this.formKonfirmasi = null
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
        };
      }
      )
    },
    getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let hem = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
          // hem.map((item) => {
          //   item.value = item.id;
          //   item.text = item.saldo >= 0 ? item.nama + " -> " + this.formatRupiah(item.saldo) : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          // });
          this.id_kas = hem;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getAkun() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akuns = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
          akuns.map((item) => {
            item.value = item.id;
            item.text = item.saldo >= 0 ? item.nama + " -> " + this.formatRupiah(item.saldo) : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          });

          this.debitAkun = akuns.filter((akun) => [this.JENIS_AKUN.debit, this.JENIS_AKUN.debit_kredit].includes(akun.jenis));
          this.kreditAkun = akuns.filter((akun) => [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(akun.jenis));
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getMember() {
      const payload = {
        order: "desc",
        start: 0,
        length: 1,
      };
      this.$store.dispatch("member/getData", payload).then(() => {
        // let items = JSON.parse(JSON.stringify(this.$store.state.member.datas));
        let items_total = this.$store.state.member.totals;
        this.membertotal = items_total;
      });
    },
    getBarang() {
      const payload = {
        order: "desc",
        start: 0,
        length: 1,
      };
      this.$store.dispatch("barang/getDataV2", payload).then(() => {
        // let items = JSON.parse(JSON.stringify(this.$store.state.member.datas));
        let items_total = this.$store.state.barang.totals;
        this.barangtotal = items_total;
      });
    },
    getTransaksiLunas() {
      this.loading = true;
      this.loaded = false
      var params1 = {
        start: 0,
        length: 1,
        // metode_bayar: "manual_transfer",
        // show_bukti_tf: 1,
        status_bayar: 'lunas',
        status: this.statuskirim != null ? this.statuskirim : null,
        member_id: this.member_id != null ? this.member_id : null,
        is_accounted: this.is_accounted != null ? this.is_accounted : null,
      };
      var params2 = {
        start: 0,
        length: 1,
        // metode_bayar: "manual_transfer",
        // show_bukti_tf: 1,
        status_bayar: 'belum_lunas',
        status: this.statuskirim != null ? this.statuskirim : null,
        member_id: this.member_id != null ? this.member_id : null,
        is_accounted: this.is_accounted != null ? this.is_accounted : null,
      };
      this.$store
        .dispatch("transaksiOnline/getData2", params1)
        .then((resp) => {
          // let items = JSON.parse(JSON.stringify(this.$store.state.transaksiOnline.datas));
          // let items_total = this.$store.state.transaksiOnline.totals;
          // let items_masuk1 = this.$store.state.transaksiOnline.masuks;
          // this.items = items;
          // this.totaltransaksi = items_total;
          let respon = resp.data.total_uang_masuk
          let tran = resp.data.recordsTotal
          this.totaltransaksimasuklunas = respon;
          this.$store
            .dispatch("transaksiOnline/getData2", params2)
            .then((resp2) => {
              // let items = JSON.parse(JSON.stringify(this.$store.state.transaksiOnline.datas));
              // let items_total = this.$store.state.transaksiOnline.totals;
              // let items_masuk2 = this.$store.state.transaksiOnline.masuks;
              // this.items = items;
              // this.totaltransaksi = items_total;
              let respon2 = resp2.data.total_uang_masuk
              let tran2 = resp2.data.recordsTotal
              this.totaltransaksimasukbelum = respon2;
              this.totaltransaksi = tran + tran2
              const payloadkonfirmasi = {
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  responsiveAnimationDuration: 500,
                  cutoutPercentage: 60,
                  legend: { display: false },
                  tooltips: {
                    callbacks: {
                      label(tooltipItem, data) {
                        const label = data.datasets[0].labels[tooltipItem.index] || ''
                        const value = data.datasets[0].data[tooltipItem.index]
                        const output = ` ${label} : ${value}`
                        return output
                      },
                    },
                    // Updated default tooltip UI
                    shadowOffsetX: 1,
                    shadowOffsetY: 1,
                    shadowBlur: 8,
                    shadowColor: chartColors.tooltipShadow,
                    backgroundColor: $themeColors.light,
                    titleFontColor: $themeColors.dark,
                    bodyFontColor: $themeColors.dark,
                  },
                },
                data: {
                  datasets: [
                    {
                      labels: ['Lunas', 'Belum Lunas'],
                      data: [this.totaltransaksimasuklunas ? this.totaltransaksimasuklunas : 0, this.totaltransaksimasukbelum ? this.totaltransaksimasukbelum : 0],
                      backgroundColor: [chartColors.successColorShade, chartColors.warningLightColor],
                      borderWidth: 0,
                      pointStyle: 'rectRounded',
                    },
                  ],
                },
              }
              this.doughnutChart = payloadkonfirmasi
              this.loaded = true
              this.loading = false;
            })
          // console.log('RESPON', this.totaltransaksimasuklunas)
        })
    },
  },
};
</script>
